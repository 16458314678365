import React from "react";

import waveTop from "../static/borderBooking/Borde_Superior.svg";
import waveBottom from "../static/borderBooking/Borde_Inferior.svg";
import waveLeft from "../static/borderBooking/Borde_Izquierdo.svg";
import waveRigth from "../static/borderBooking/Borde_Derecho.svg";

import waveTopBlack from "../static/wave-top-black.svg";
import waveBottomBlack from "../static/wave-bottom-black.svg";
import waveLeftBlack from "../static/wave-left-black.svg";
import waveRigthBlack from "../static/wave-rigth-black.svg";

import "../styles/components/BorderButtons.scss";

function BorderButtonBooking() {
  return (
    <div className="wave-decoration-btn">
      <img className="wave top gray" src={waveTop} alt="" />
      <img className="wave bottom gray" src={waveBottom} alt="" />
      <img className="wave left gray" src={waveLeft} alt="" />
      <img className="wave right gray" src={waveRigth} alt="" />
      <img className="wave top black" src={waveTopBlack} alt="" />
      <img className="wave bottom black" src={waveBottomBlack} alt="" />
      <img className="wave left black" src={waveLeftBlack} alt="" />
      <img className="wave right black" src={waveRigthBlack} alt="" />
    </div>
  );
}

export default BorderButtonBooking;
