export const otherDayHour = [
  { hour: "13:00hrs" },
  { hour: "13:30hrs" },
  { hour: "14:00hrs" },
  { hour: "14:30hrs" },
  { hour: "15:00hrs" },
  { hour: "15:30hrs" },
  { hour: "18:30hrs" },
  { hour: "19:00hrs" },
  { hour: "19:30hrs" },
  { hour: "20:00hrs" },
  { hour: "20:30hrs" },
  { hour: "21:00hrs" },
  { hour: "21:30hrs" },
];

export const sundayHour = [
  { hour: "13:00hrs" },
  { hour: "13:30hrs" },
  { hour: "14:00hrs" },
  { hour: "14:30hrs" },
  { hour: "15:00hrs" },
  { hour: "15:30hrs" },
];