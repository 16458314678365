/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useEffect, useState } from "react";

import Calendar from "react-calendar";
import Fade from "react-reveal/Fade";

import emailjs from "emailjs-com";
import moment from "moment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import BorderButtonBooking from "./BorderButtonBooking";

import { otherDayHour, sundayHour } from "../constants/hours";

import "react-calendar/dist/Calendar.css";
import "../styles/components/Reservation.scss";

const SwalReservation = withReactContent(Swal);

function Reservation() {
  const disabledDates = [new Date(2024, 14, 2)];
  const [step, setStep] = useState("First");
  const [dateState, setDateState] = useState(new Date());
  const [reservationHour, setReservationHour] = useState([]);
  const [showTypesReservation, setShowTypesReservation] = useState(true);
  const [showDegustationReservation, setShowDegustationReservation] =
    useState(false);
  const [checkDateReservation, setCheckDateReservation] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [reservation, setReservation] = useState({
    createdAt: "",
    date: "",
    name: "",
    phone: "",
    email: "",
    hour: "",
    quant: "",
    status: "pending",
    typeBooking: "normal",
  });

  const changeDate = async (event) => {
    const selectDay = moment(event).weekday();

    setReservation({
      ...reservation,
      date: moment(event).format("DD-MM-YYYY"),
    });

    setDateState(event);
    setCheckDateReservation(true);

    if (selectDay === 0) {
      setReservationHour(sundayHour);
    } else {
      setReservationHour(otherDayHour);
    }

    if (moment(event).format("L") === "10/08/2023") {
      setReservationHour(otherDayHour);
    }
  };

  const changeFirstStep = async () => {
    setReservation({
      ...reservation,
      createdAt: moment().format("DD-MM-YYYY/HH:mm"),
    });

    setStep("First");
  };

  const changeSecondStep = async () => {
    setReservation({
      ...reservation,
      createdAt: moment().format("DD-MM-YYYY/HH:mm"),
    });

    setStep("Second");
  };

  const changeThirdStep = async () => {
    setReservation({
      ...reservation,
      createdAt: moment().format("DD-MM-YYYY/HH:mm"),
    });

    setStep("Third");
  };

  const handleInputChange = async (event) => {
    setReservation({
      ...reservation,
      [event.target.name]: event.target.value,
    });
  };

  const sendEmailReservation = async (reservation, typeBooking) => {
    await emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_EMAIL_TEMPLATE,
        reservation,
        process.env.REACT_APP_EMAIL_USER
      )
      .then(
        async (response) => {
          await SwalReservation.fire({
            didOpen: () => {
              SwalReservation.clickConfirm();

              setSendingMail(false);
            },
          }).then(() => {
            return SwalReservation.fire({
              title: <p>Solicitud de reserva realizada</p>,
              text: "Pronto te contactaremos para confirmar tu reserva",
              footer: "La Caperucita y El Lobo",
            });
          });

          setStep("First");
        },
        (error) => {
          console.log("Oops, intentalo nuevamente", error);
        }
      );
  };

  const sendData = async (event) => {
    event.preventDefault();
    try {
      setSendingMail(true);

      // // Send data to firestore
      // await pushDataToFirestore(reservation);

      // Send email at client
      await sendEmailReservation(reservation);
    } catch (error) {
      console.log("Error al enviar el formulario");
    }
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          mutation.addedNodes.forEach((node) => {
            node.querySelectorAll &&
              node
                .querySelectorAll(
                  '.react-calendar__tile.react-calendar__month-view__days__day abbr[aria-label="February 14, 2024"]'
                )
                .forEach((abbr) => {
                  abbr.parentElement.classList.add("special-day");
                });
          });
        }
      });
    });

    const config = { childList: true, subtree: true };

    // Observar cambios en el elemento raíz de tu aplicación o un contenedor específico
    // Reemplaza 'document.body' con el elemento que deseas observar
    observer.observe(document.body, config);

    return () => observer.disconnect();
  }, []);

  return (
    <section className="Booking-section">
      <div className="Booking-steps">
        {showTypesReservation ? (
          <div className="overlay-newBook">
            <div className="content-newBook">
              <div className="title-newBook">
                <h1>Reserva tu hora</h1>
                <p>Selecciona una opcion:</p>
              </div>

              <div className="options-newBook">
                <button
                  type="button"
                  onClick={(e) => {
                    setShowTypesReservation(false);
                  }}
                >
                  <BorderButtonBooking />
                  <span>A la carta</span>
                </button>
              </div>

              <p>
                * La Reserva de degustación es hábil solo para los dias viernes
                y Sábados de cada semana
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {showDegustationReservation ? (
          <Fragment>
            <div className="wrap-dots-steps">
              <ul>
                <li
                  onClick={changeFirstStep}
                  className={step === "First" ? "active" : ""}
                >
                  <span>1</span>
                </li>
                <li
                  onClick={changeSecondStep}
                  className={step === "Second" ? "active" : ""}
                >
                  <span>2</span>
                </li>
                <li
                  onClick={changeThirdStep}
                  className={step === "Third" ? "active" : ""}
                >
                  <span>3</span>
                </li>
              </ul>
            </div>

            <div className="Booking-content degustation">
              {(() => {
                switch (step) {
                  case "First":
                    return (
                      <Fade ssrFadeout>
                        <div className="First-step">
                          <p>¿En cuál fecha deseas venir?</p>
                          <Calendar
                            className="calendar"
                            value={dateState}
                            onChange={changeDate}
                            calendarType="ISO 8601"
                          />
                          <button
                            type="button"
                            onClick={
                              checkDateReservation
                                ? changeSecondStep
                                : undefined
                            }
                          >
                            <BorderButtonBooking />
                            {checkDateReservation ? (
                              <span>Avanza al siguiente paso</span>
                            ) : (
                              <span>Selecciona una fecha</span>
                            )}
                          </button>
                        </div>
                      </Fade>
                    );

                  case "Second":
                    return (
                      <Fade ssrFadeout>
                        <div className="Second-step">
                          <p>¿A qué hora y con cuánta gente vendrías?</p>
                          <select
                            name="hour"
                            id="hourSelect"
                            onChange={handleInputChange}
                          >
                            <option defaultValue>ELIGE TU HORARIO</option>
                            <option value="19:30hrs">19:30hrs</option>
                            <option value="20:00hrs">20:00hrs</option>
                            <option value="20:30hrs">20:30hrs</option>
                          </select>

                          <select
                            name="quant"
                            id="quantSelect"
                            onChange={handleInputChange}
                          >
                            <option defaultValue>CUÁNTAS PERSONAS SON?</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="8+">8+</option>
                          </select>

                          <button type="button" onClick={changeThirdStep}>
                            <BorderButtonBooking />
                            <span>Avanza al siguiente paso</span>
                          </button>
                        </div>
                      </Fade>
                    );

                  case "Third":
                    return (
                      <Fade ssrFadeout>
                        <div className="Third-step">
                          <p>Déjanos tu nombre y tus datos de contacto</p>
                          <form action="" onSubmit={sendData}>
                            <input
                              type="text"
                              name="name"
                              id="nameContact"
                              onChange={handleInputChange}
                              placeholder="Nombre Completo"
                              required
                            />
                            <input
                              type="number"
                              name="phone"
                              id="phoneContact"
                              onChange={handleInputChange}
                              placeholder="Celular"
                              required
                            />
                            <input
                              type="email"
                              name="email"
                              id="emailContacto"
                              onChange={handleInputChange}
                              placeholder="E-Mail"
                              required
                            />

                            <div className="container-button">
                              <BorderButtonBooking />
                              <input
                                type="submit"
                                disabled={sendingMail ? true : false}
                                value={sendingMail ? "Enviando" : "Reservar"}
                              />
                            </div>
                          </form>
                        </div>
                      </Fade>
                    );
                }
              })()}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="wrap-dots-steps">
              <ul>
                <li
                  onClick={changeFirstStep}
                  className={step === "First" ? "active" : ""}
                >
                  <span>1</span>
                </li>
                <li
                  onClick={changeSecondStep}
                  className={step === "Second" ? "active" : ""}
                >
                  <span>2</span>
                </li>
                <li
                  onClick={changeThirdStep}
                  className={step === "Third" ? "active" : ""}
                >
                  <span>3</span>
                </li>
              </ul>
            </div>

            <div className="Booking-content">
              {(() => {
                switch (step) {
                  case "First":
                    return (
                      <Fade ssrFadeout>
                        <div className="First-step">
                          <p>¿En cuál fecha deseas venir?</p>
                          <Calendar
                            tileDisabled={(date, view) =>
                              view === "month" && // Block day tiles only
                              disabledDates.some(
                                (disabledDate) =>
                                  date.getFullYear() ===
                                    disabledDate.getFullYear() &&
                                  date.getMonth() === disabledDate.getMonth() &&
                                  date.getDate() === disabledDate.getDate()
                              )
                            }
                            className="calendar"
                            value={dateState}
                            onChange={changeDate}
                            calendarType="ISO 8601"
                          />
                          <button
                            type="button"
                            onClick={
                              checkDateReservation
                                ? changeSecondStep
                                : undefined
                            }
                          >
                            <BorderButtonBooking />
                            {checkDateReservation ? (
                              <span>Avanza al siguiente paso</span>
                            ) : (
                              <span>Selecciona una fecha</span>
                            )}
                          </button>
                        </div>
                      </Fade>
                    );

                  case "Second":
                    return (
                      <Fade ssrFadeout>
                        <div className="Second-step">
                          <p>¿A qué hora y con cuánta gente vendrías?</p>
                          <select
                            name="hour"
                            id="hourSelect"
                            onChange={handleInputChange}
                          >
                            <option defaultValue>ELIGE TU HORARIO</option>
                            {reservationHour.map((hourDay, index) => {
                              return (
                                <option key={index} value={hourDay.hour}>
                                  {hourDay.hour}
                                </option>
                              );
                            })}
                          </select>

                          <select
                            name="quant"
                            id="quantSelect"
                            onChange={handleInputChange}
                          >
                            <option defaultValue>CUÁNTAS PERSONAS SON?</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="8+">8+</option>
                          </select>

                          <button type="button" onClick={changeThirdStep}>
                            <BorderButtonBooking />
                            <span>Avanza al siguiente paso</span>
                          </button>
                        </div>
                      </Fade>
                    );

                  case "Third":
                    return (
                      <Fade ssrFadeout>
                        <div className="Third-step">
                          <p>Déjanos tu nombre y tus datos de contacto</p>
                          <form action="" onSubmit={sendData}>
                            <input
                              type="text"
                              name="name"
                              id="nameContact"
                              onChange={handleInputChange}
                              placeholder="Nombre Completo"
                              required
                            />
                            <input
                              type="number"
                              name="phone"
                              id="phoneContact"
                              onChange={handleInputChange}
                              placeholder="Celular"
                              required
                            />
                            <input
                              type="email"
                              name="email"
                              id="emailContacto"
                              onChange={handleInputChange}
                              placeholder="E-Mail"
                              required
                            />

                            <div className="container-button">
                              <BorderButtonBooking />
                              <input
                                type="submit"
                                disabled={sendingMail ? true : false}
                                value={sendingMail ? "Enviando" : "Reservar"}
                              />
                            </div>
                          </form>
                        </div>
                      </Fade>
                    );
                }
              })()}
            </div>
          </Fragment>
        )}
      </div>
    </section>
  );
}

export default Reservation;
